import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "@material-ui/core";
import classes from "../Testimonials.module.css";
import StarRatings from "react-star-ratings";
import Utils from "../../../Utils";
import Modal from "../../../components/Modal/Modal";

const text =
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi saepe culpa sed nulla fuga vero praesentium, ab corporis omnis amet aut molestias vel, necessitatibus expedita mollitia laudantium animi eaque inventore! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi saepe culpa sed nulla fuga vero praesentium, ab corporis omnis amet aut molestias vel, necessitatibus expedita mollitia laudantium animi eaque inventore! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi saepe culpa sed nulla fuga vero praesentium, ab corporis omnis amet aut molestias vel, necessitatibus expedita mollitia laudantium animi eaque inventore!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi saepe culpa sed nulla fuga vero praesentium, ab corporis omnis amet aut molestias vel, necessitatibus expedita mollitia laudantium animi eaque inventore!";

const LINE = 4;

const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();
};

function TestimonialCard({ testimonial }) {
  const [showButton, setShowButton] = useState(false);
  const [show, setShow] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const computedStyle = window.getComputedStyle(textRef.current);
        let lineHeight = parseFloat(computedStyle.lineHeight);
        // Default line height if `normal` is returned
        if (isNaN(lineHeight)) {
          lineHeight = 20; // Adjust based on expected styles
        }

        const height = textRef.current.clientHeight;
        const lines = height / lineHeight;
        setShowButton(lines > LINE);
      }
    };

    // Ensure it runs after layout calculation
    requestAnimationFrame(checkOverflow);
  }, []);

  return (
    <>
      <div className={classes.testimonialCard}>
        <div className={classes.testimonialCardContent}>
          <div>
            <div className={classes.testimonialCardHeader}>
              <Avatar src={testimonial?.authorPhotoUrl} style={{background: "var(--selection-dark)"}}>
                {getInitials(testimonial?.authorName) || "B"}
              </Avatar>
              <span>{testimonial?.authorName} | {testimonial?.bookName}</span>
            </div>
          </div>
          <div className={classes.testimonialCardRatings}>
            <StarRatings
              rating={testimonial?.rating || 0}
              starRatedColor={"var(--selection-dark)"}
              numberOfStars={5}
              starDimension="1.5rem"
              starSpacing="0.25rem"
            />
          </div>

          <div>
            <p
              ref={textRef}
              className={classes.description}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: LINE,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {testimonial?.comment}
            </p>

            {showButton && (
              <button
                className={classes.readMoreButton}
                onClick={() => setShow(true)}
              >
                Show More
              </button>
            )}
          </div>

          {/* <div className={classes.testimonialCardFooter}>
            {Utils.getFormattedDate(testimonial?.updateTime)}
          </div> */}
        </div>
      </div>
      {show && (
        <Modal
          title={
            <div className={classes.testimonialCardHeader}>
              <Avatar src={testimonial?.authorPhotoUrl}>
                {getInitials(testimonial?.authorName) || "B"}
              </Avatar>
              <span>{testimonial?.authorName}</span>
            </div>
          }
          onClose={() => setShow(false)}
        >
          <>
              <div className={classes.testimonialCardRatings}>
                <StarRatings
                  rating={testimonial?.rating || 0}
                  starRatedColor={"#FBB805"}
                  numberOfStars={5}
                  starDimension="1.5rem"
                  starSpacing="0.25rem"
                />
              </div>
              <p className={classes.description}>{testimonial?.comment}</p>
            </>
        </Modal>
      )}
    </>
  );
}

export default TestimonialCard;
