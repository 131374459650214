import React, { useEffect } from "react";
import classes from "./Testimonials.module.css";
import testimonialHeroImage from "../../assets/images/testimonials-hero.jpg";
import TestimonialCard from "./TestimonialCard/TestimonialCard";
import TestimonialsVideo from "./TestimonialsVideo/TestimonialsVideo";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestimonials } from "./TestimonialsAction";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

function Testimonials() {
  const dispatch = useDispatch();
  const testimonials = useSelector(
    (state) => state.testimonials.allTestimonials
  );

  useEffect(() => {
    fetchTestimonials(dispatch);
  }, [dispatch]);

  return (
    <div className={classes.main}>
      <div
        className={classes.heroImageContainer}
        onClick={() =>
          window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSdTbzzmWvphJ6EnT1FeAKtoghJmWOk-DG2mFAF8i1vm0WNIag/viewform"
          )
        }
      >
        <img src={testimonialHeroImage} alt={testimonialHeroImage} />
        <div className={classes.heroButton}>
          <PrimaryButton className={classes.heroBtn}>Be The Next Star Author</PrimaryButton>
        </div>
      </div>
      <div className={classes.testimonialsContainer}>
        <div className={classes.gridContainer}>
          {testimonials.map((testimonial, index) => {
            const Component =
              testimonial.type === "video"
                ? TestimonialsVideo
                : TestimonialCard;
            return <Component key={index} testimonial={testimonial} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
