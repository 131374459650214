import React, { useEffect } from "react";
import classes from "../HomeScreen.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEditorsChoiceBooks } from "../../Books/BooksActions";

function EditorsChoice() {
  const dispatch = useDispatch();
  const books = useSelector((state) => state.books.editorsChoice);

  useEffect(() => {
    getEditorsChoiceBooks(dispatch)
  }, [dispatch])

  return (
    <div className={classes.editorChoiceMain}>
      <h3 className={classes.editorChoiceHeading}>
        Editor’s Choice: Top Books
      </h3>
      <div className={classes.imageContainer}>
        <div className={classes.editorsChoiceWrapper}>
          {Boolean(books?.length) ? (
            books.map((e) => (
              <Link to={`/details/${e.urlSlag}`} key={e.urlSlag}>
                <img src={e.frontThumbURL} alt={e.urlSlag} />
              </Link>
            ))
          ) : (
            <div className={classes.noBooks}>No books to show</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditorsChoice;
