import React from "react";
import classes from "./AboutUs.module.css";
import MainFooter from "../../components/MainFooter/MainFooter";
import AboutBanner from "../../assets/images/aboutUsBanner.webp";
import AboutBannerMobile from "../../assets/images/aboutUsBannerMobile.jpg";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useHistory } from "react-router-dom";
import Utils from "../../Utils";
import { Button } from "@material-ui/core";
import Consts from "../../Consts";
import TeamCard from "../../components/TeamCard/TeamCard";
import { teamMembers } from "../../lib/Constants";

const content = [
  <>
    Welcome to Bookalooza, the ultimate self-publishing AI platform where young
    minds turn their stories into real books! If you’ve ever dreamed of becoming
    an author, this is your chance. <b>Why should you join Bookalooza?</b>{" "}
    Because we make writing fun, easy, and rewarding!
  </>,
  <>
    With in-built templates, customizable color, font styles, and headings, and
    even a speech-to-text feature for days when you don’t feel like typing,
    writing a book has never been this simple. Our AI support guides you every
    step of the way, making the process completely hassle-free. Need help with
    editing? Our proofreading services have got you covered. Plus, your book
    gets listed on Amazon and Flipkart, reaching readers everywhere!
  </>,
  <>
    And that’s not all—your journey doesn’t end with publishing. We feature
    young authors on our exclusive podcast, streamed live on YouTube and social
    media. With exciting themes and creative tools, Bookalooza is where young
    writers become published authors and even earn royalties from their books!
  </>,
  <>
    Start writing today—<strong>your story deserves to be told</strong>!
  </>,
];

function AboutUs() {
  const history = useHistory();
  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.aboutImageContainer}>
          <picture>
            <source media="(max-width: 768px)" srcSet={AboutBannerMobile} />
            <source media="(min-width: 769px)" srcSet={AboutBanner} />
            <img src={AboutBanner} alt={AboutBanner} loading="lazy" />
          </picture>
          <div className={classes.createButtonWrapper}>
            <PrimaryButton
              className={classes.aboutCreateButton}
              onClick={() => {
                Utils.createBook(history);
              }}
            >
              Create your Book Now!
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.buttonBar}>
          <Button
            onClick={() => {
              Utils.createBook(history);
            }}
          >
            Start Writing
          </Button>
          <Button onClick={() => history.push(`${Consts.BASE_URL}/blogs`)}>
            Read Blogs
          </Button>
          <Button
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdTbzzmWvphJ6EnT1FeAKtoghJmWOk-DG2mFAF8i1vm0WNIag/viewform",
                "_blank"
              )
            }
          >
            Book Podcast
          </Button>
          <Button
            onClick={() =>
              window.open(
                "https://whatsapp.com/channel/0029Vak6zGaAe5VwdHcBmx0a",
                "_blank"
              )
            }
          >
            Follow on Whatsapp
          </Button>
        </div>

        {/* New Content Section */}
        <div className={classes.aboutContentSection}>
          <h2>Welcome to Bookalooza</h2>
          {content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          <h2>Our Team</h2>
          <div className={classes.ourTeamContainer}>
            {teamMembers.map((member, index) => (
              <TeamCard key={index} teamPerson={member} />
            ))}
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default AboutUs;
