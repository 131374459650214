import ankur from "../assets/images/ankur.png";
import anuj from "../assets/images/anuj.png";
import partho from "../assets/images/partho.png";
import parul from "../assets/images/parul.png";
import manu from "../assets/images/mannu.png";
import amita from "../assets/images/amita.png";
import yogesh from "../assets/images/yogesh.png";
import daulat from "../assets/images/daulat.png";
import deepak from "../assets/images/deepak.png";

export const teamMembers = [
    {
      name: "Ankur Gupta",
      designation: "Director",
      image: ankur,
      details: {
        industry: [
          "Branding, Sales",
          "Growth",
          "Strategy",
          "Market",
          "Influence",
          "Revenue",
          "Advertising",
          "Networking",
          "Customer Acquisition",
        ],
        personal: [
          "Persuasive",
          "Visionary",
          "Strategic",
          "Energetic",
          "Goal-driven",
          "Motivational",
          "Adaptable",
          "Outgoing",
          "Dynamic",
        ],
      },
    },
    {
      name: "Deepak Garg",
      designation: "Director",
      image: deepak,
      details: {
        industry: [
          "Software Development",
          "AI & Automation",
          "Cybersecurity",
          "Cloud Computing",
          "Data Analytics",
          "System Architecture",
          "Scalability & Performance",
          "Product Innovation",
          "Tech Strategy",
        ],
        personal: [
          "Analytical",
          "Problem-solver",
          "Innovative",
          "Logical",
          "Detail-oriented",
          "Resilient",
          "Tech-savvy",
          "Strategic",
          "Adaptive",
          "Visionary",
        ],
      },
    },
    {
      name: "Amita Wadhwani",
      designation: "GM- Marketing",
      image: amita,
      details: {
        industry: [
          "Brand Strategy",
          "Digital Marketing",
          "Content Marketing",
          "Social Media Management",
          "Campaign Planning",
          "Market Research",
          "SEO & SEM",
          "Customer Engagement",
          "Performance Analytics",
        ],
        personal: [
          "Book Reading",
          "Meditation",
          "Exercise",
          "Reeling",
          "Networking",
          "Public Speaking",
          "Traveling",
          "Blogging",
          "Photography",
          "Music",
          "Copywriting",
        ],
      },
    },
    {
      name: "Daulat Singh",
      designation: "Video Editor",
      image: daulat,
      details: {
        industry: [
          "Editing",
          "Storytelling",
          "Transitions",
          "Motion",
          "Effects",
          "Color-grading",
          "Sound",
          "Composition",
          "Production",
        ],
        personal: [
          "Creative",
          "Detail-oriented",
          "Perceptive",
          "Storyteller",
          "Expressive",
          "Innovative",
          "Patient",
          "Observant",
          "Adaptive",
        ],
      },
    },
    {
      name: "Partho Banerjee",
      designation: "Graphics Designer",
      image: partho,
      details: {
        industry: [
          "Design",
          "Branding",
          "Illustration",
          "Typography",
          "Visuals",
          "Graphics",
          "Creativity",
          "Composition",
          "Photoshop Expert",
        ],
        personal: [
          "Creative",
          "Visionary",
          "Detail-oriented",
          "Artistic",
          "Passionate",
          "Innovative",
          "Imaginative",
          "Expressive",
          "Observant",
        ],
      },
    },
    // {
    //   name: "Pankaj Sharma",
    //   designation: "Graphic Designer",
    //   image: partho,
    //   details: {
    //     industry: [
    //       "Typesetting",
    //       "Page Layout",
    //       "Cover Design",
    //       "Print Production",
    //       "Proofing",
    //       "E-book Formatting",
    //       "Typography Selection",
    //       "Color Correction",
    //       "Vector Graphics",
    //       "Image Editing",
    //     ],
    //     personal: [
    //       "Family time",
    //       "Playing with kids",
    //       "Health care",
    //       "Movies",
    //       "Music",
    //       "Cooking",
    //       "Travel",
    //       "Gardening",
    //       "Board games",
    //       "Photography",
    //     ],
    //   },
    // },
    {
      name: "Parul Rana",
      designation: "Marketing Executive",
      image: parul,
      details: {
        industry: [
          "Branding",
          "Outreach",
          "Digital",
          "Engagement",
          "Strategy",
          "WhatsApp Advertising",
          "Social Media",
          "Campaigns",
          "Podcasting",
        ],
        personal: [
          "Persuasive",
          "Outgoing",
          "Strategic",
          "Passionate",
          "Creative",
          "Adaptable",
          "Data-driven",
          "Goal-oriented",
          "Visionary",
        ],
      },
    },
    {
      name: "Manu Sharma",
      designation: "Marketing Coordinator",
      image: manu,
      details: {
        industry: [
          "Planning",
          "Execution",
          "Books Editing",
          "Dispatch",
          "Customer Coordination",
          "Team Coordination",
          "Agency Management",
        ],
        personal: [
          "Organized",
          "Detail-oriented",
          "Communicative",
          "Strategic",
          "Collaborative",
          "Analytical",
          "Adaptable",
          "Proactive",
          "Resourceful",
          "Multitasker",
        ],
      },
    },
    {
      name: "Anuj Chauhan",
      image: anuj,
      designation: "Telesales cum Account Executive",
      details: {
        industry: [
          "Customer Interaction",
          "Lead Generation",
          "Follow-ups",
          "CRM Management",
          "Order Processing",
          "Data Entry",
          "Problem Solving",
          "Target Achievement",
          "Coordination",
        ],
        personal: [
          "Friendly",
          "Talkative",
          "Multitasker",
          "Adaptable",
          "Team Player",
          "Coffee Lover",
          "Social Media Scroller",
          "Quick Learner",
        ],
      },
    },
    {
      name: "Yogesh Dhengale",
      designation: "Software Engineer",
      image: yogesh,
      details: {
        industry: [
          "Software Development",
          "Mobile App Development",
          "API Integration",
          "UX Design",
          "Project Management",
          "Testing",
          "Maintenance",
          "Debugging",
        ],
        personal: [
          "System Architecture",
          "Scalability & Performance",
          "Product Innovation",
          "Tech Strategy",
          "User Experience (UX) Optimization",
        ],
      },
    },
  ];