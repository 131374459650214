import { createSlice } from "@reduxjs/toolkit";

const blogEventSlice = createSlice({
    name: 'blogEvents',
    initialState: {
        allBlogsList: [],
        totalBlogsCount: 0,
        blog: {}
    },
    reducers: {
        fetchBlogEvents: (state, action) => {
            state.allBlogsList = action.payload.blogList;
        },
        blogsTotalCount: (state, action) => {
            state.totalBlogsCount = action.payload.count;
        },
        fetchBlog: (state, action) => {
            state.blog = action.payload;
        }
    }

});

export const blogsEventAction = blogEventSlice.actions;
export default blogEventSlice.reducer;