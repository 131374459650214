import axios from "axios";
import { blogsEventAction } from "./BlogsSlice";

export function fetchAllBlogEvents(page, pageSize, dispatch) {
  let filter = {
    where: {
      $or: [
        { scheduledTime: { $lte: new Date().getTime() } }, // Condition 1: scheduledTime is present and less than current time
        { scheduledTime: { $exists: false } }             // Condition 2: scheduledTime is not present
      ]
    },
    "offset": (page - 1) * pageSize,
    "limit": pageSize,
    sortBy: [
      "pinned DESC",
      "_id DESC"
    ]
  };
  return axios({
    url: `/designer/blogs?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    method: 'get',
    responseType: 'json'
  }).then(function (response) {
    let data = response.data;
    if (data.success) {
      dispatch(blogsEventAction.fetchBlogEvents({ blogList: data?.data || [] }))
    }
  }).catch(function (error) {
    return error;
  })

}

export function fetchBlog(dispatch, slug) {
  return axios({
    url: `/designer/blogs/slag/${slug}`,
    method: "get",
    responseType: "json"
  }).then(e => {
    if(e.data.success) {
      dispatch(blogsEventAction.fetchBlog(e.data.data))
    }
  })
}

export function getContent(contentDataPath) {
  return axios({
    url: contentDataPath,
    method: 'get',
  }).then(response => response.data)
}

export function fetchBlogsCount(dispatch) {
  let where = {
    $or: [
      { scheduledTime: { $lte: new Date().getTime() } }, // Condition 1: scheduledTime is present and less than current time
      { scheduledTime: { $exists: false } }             // Condition 2: scheduledTime is not present
    ]
  }
  axios({
    url: `/designer/blogs/count?where=${encodeURIComponent(JSON.stringify(where))}`,
    method: "get",
    responseType: "json",
  }).then(response => {
    let data = response.data;
    if (data.success) {
      dispatch(blogsEventAction.blogsTotalCount({ count: data.data.count }))
    }
  })
}