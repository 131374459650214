import React from "react";
import classes from "./MainFooter.module.css";
import facebookIcon from "../../assets/social/facebook.svg";
import youtubeIcon from "../../assets/social/youtube.svg";
import instaIcon from "../../assets/social/instagram.svg";
import twitterIcon from "../../assets/social/twitter.svg";
import linkedInIcon from "../../assets/social/linkedin-round-svgrepo-com.svg";
import intrestIcon from "../../assets/social/pintrest.svg";
import whatsapp from "../../assets/social/whatsapp.svg";
import { useHistory } from "react-router";
import logoImage from "../../assets/campaign/Bookalooza-New-Logo_White.png";
import Utils from "../../Utils";
import { useSelector } from "react-redux";
import Consts from "../../Consts";

export function SocialMedia() {
  return (
    <div className={classes.socialBox}>
      <ul>
        <li>
          <a href="https://www.facebook.com/bookalooza" target="_blank" rel="noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/bookalooza/" target="_blank" rel="noreferrer">
            <img src={instaIcon} alt="Instagram" />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@Bookalooza?sub_confirmation=1" target="_blank" rel="noreferrer">
            <img src={youtubeIcon} alt="Youtube" />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/bookalooza/" target="_blank" rel="noreferrer" >
            <img src={linkedInIcon} alt="LinkedIn" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/bookalooza" target="_blank" rel="noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </li>
        <li>
          <a href="https://in.pinterest.com/Bookaloozacreatebooks" target="_blank" rel="noreferrer" >
            <img src={intrestIcon} alt="Pinterest" />
          </a>
        </li>
        <li>
          <a href="https://whatsapp.com/channel/0029Vak6zGaAe5VwdHcBmx0a" target="_blank" rel="noreferrer" >
            <img src={whatsapp} alt="whatsapp" />
          </a>
        </li>
      </ul>
    </div>
  );
}

const url = Consts.BASE_URL;
function MainFooter() {
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  return (
    <footer className={classes.mainContainer}>
      <div className={classes.detailsBox}>
        <div className={classes.productBox}>
          <div className={classes.productBoxLogoAndContent}>
            <div
              className={classes.bookAloozaLogo}
              onClick={() => history.push(url)}
            >
              <img src={logoImage} className={classes.logo} alt="bookalooza" />
              {/* <img
                src={logoWithTagline}
                className={classes.tagline}
                alt="tagline"
              /> */}
            </div>
            <SocialMedia />
          </div>
        </div>
        <div className={classes.footerLinks}>
          <div className={classes.exploreBox}>
            <h3>Explore</h3>
            <ul>
              <li
                className={classes.exploreBoxItem}
                onClick={() => history.push(`${url}/about-us`)}
              >
                About us
              </li>
              {!user && (
                <li
                  className={classes.exploreBoxItem}
                  onClick={() => history.push(`${url}/sign-in`)}
                >
                  Login
                </li>
              )}
              <li
                className={classes.exploreBoxItem}
                onClick={() => history.push(`${url}/contact-us`)}
              >
                Contact us
              </li>
              <li
                className={classes.exploreBoxItem}
                onClick={() => Utils.openBookstore(history)}
              >
                Shop with us
              </li>
              <li
                className={classes.exploreBoxItem}
                onClick={() => history.push(`${url}/pricing-royalties`)}
              >
                Pricing and Royalties
              </li>
              <li
                className={classes.exploreBoxItem}
                onClick={() =>
                  history.push(`${url}/frequently-asked-questions`)
                }
              >
                Frequently Asked Questions
              </li>
            </ul>
          </div>
          <div className={classes.quickLinksBox}>
            <h3>Quick Links</h3>
            <ul>
              <li
                className={classes.exploreBoxItem}
                onClick={() => history.push(`${url}/terms-and-condition`)}
              >
                Terms and Conditions
              </li>
              <li
                className={classes.exploreBoxItem}
                onClick={() => history.push(`${url}/refund-cancellation`)}
              >
                Refund and Cancellations
              </li>
              <li
                className={classes.exploreBoxItem}
                onClick={() => history.push(`${url}/shipping-policy`)}
              >
                Shipping Policy
              </li>
              {/* <li className={classes.exploreBoxItem} onClick={() => history.push(`${url}/license-agreement`)}>License Agreement</li> */}
              <li
                className={classes.exploreBoxItem}
                onClick={() => history.push(`${url}/privacy-policies`)}
              >
                Privacy Policies
              </li>
              <li
                className={classes.exploreBoxItem}
                onClick={(e) => {
                  window.location.href = "/school-ui/login";
                }}
              >
                School Login
              </li>
            </ul>
          </div>
          <div className={classes.contactUsBox}>
            <h3>Contact us</h3>
            <ul>
              <li>
                <div>
                  <p>Call us</p>
                  <p>
                    <a className={classes.phone} href="tel:+918799721408">
                      +91 8799721408
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p>Email id</p>
                  <p className={classes.mail}>
                    <a href="mailto:info@bookalooza.com">info@bookalooza.com</a>
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p>Reach us</p>
                  <p className={classes.address}>Orange Vtech Pvt Ltd</p>
                  <p className={classes.address}>
                    9, Daryaganj, New Delhi-110002 (India)
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default MainFooter;
