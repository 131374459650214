import React from "react";
import classes from "../HomeScreen.module.css";
import shahwaiz from "../../../assets/images/1.jpg"
import eshita from "../../../assets/images/2.jpg" 
import drishti from "../../../assets/images/3.jpg"
import sadhna from "../../../assets/images/4.jpg"
import parash from "../../../assets/images/5.jpg"
import riza from "../../../assets/images/6.jpg"
import saanvika from "../../../assets/images/7.jpg"
import aaradhya from "../../../assets/images/8.jpg"
import harsha from "../../../assets/images/9.jpg"
import aradhya2 from "../../../assets/images/10.jpg"
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { useHistory } from "react-router-dom";
import Consts from "../../../Consts";

const testimonials = [
  {
    name: "Shahwaiz Ansari",
    designation: "Author",
    book: "Freedom Struggle",
    text: "I realized it's a great platform that helps children nurture their talent for writing and authorship.",
    rotate: "5.349deg",
    img: shahwaiz
  },
  {},
  {},
  {
    name: "Eshita E",
    designation: "Author",
    book: "The Almighty God",
    text: "Bookalooza makes writing a book incredibly easy and accessible for everyone.",
    rotate: "-3.349deg",
    img: eshita
  },
  {},
  {
    name: "Drishti Sharma",
    designation: "Author",
    book: "अच्छी आदतें: सफलता की कुंजी",
    text: "My first book came true thanks to Bookalooza—my ultimate author companion!",
    rotate: "3.349deg",
    img: drishti
  },
  {},
  {},
  {
    name: "Sadhna Gautam",
    designation: "Author",
    book: "This is Called Life",
    text: "Bookalooza is a true platform for the children to show their talent...Thank you, Bookalooza.",
    rotate: "-15.349deg",
    img: sadhna
  },
  {},
  {
    name: "Parash Kashyap Kachari",
    designation: "Author",
    book: "The Saviour",
    text: "This is my first time writing about God, and I’m excited to share it with you all.",
    rotate: "10.349deg",
    img: parash
  },
  {},
  {},
  {
    name: "Riza Khan",
    designation: "Author",
    book: "Ouija Board and The Mysterious Portal",
    text: "My experience with Bookalooza was awesome.I faced many problems but all got resolved by Bookalooza.",
    rotate: "-10.349deg",
    img: riza
  },
  {},
  {
    name: "Saanvika Rao",
    designation: "Author",
    book: "Ambition & Dream",
    text: "It's really an amazing experience for me. I will never forget this journey with Bookalooza.",
    rotate: "-4.349deg",
    img: saanvika
  },
  {},
  {},
  {
    name: "Aaradhya Chhabra",
    designation: "Author",
    book: "The Haunted Library",
    text: "Bookalooza is such a great platform for writing a book. Now I am an author. It is just possible because of Bookalooza.",
    rotate: "4.349deg",
    img: aaradhya
  },
  {},
  {},
  {
    name: "Harsha K",
    designation: "Author",
    book: "Lord Shiva Stories",
    text: "This has been an incredible experience, and I've been thinking about writing a book for many years.",
    rotate: "5.349deg",
    img: harsha
  },
  {},
  {
    name: "Aradhya Singh",
    designation: "Author",
    book: "Covid 19",
    text: "It's a really great app, and I will love and enjoy writing on Bookalooza.👍😀",
    rotate: "-5.349deg",
    img: aradhya2
  },
];
function Testimonials() {
  const items = [...testimonials, ...testimonials];
  const history = useHistory();
  return (
    <div className={classes.testimonialsMain}>
      <div  className={classes.testimonialsHeading}>
        <h1>
          WHAT OUR STAR AUTHORS ARE SAYING
        </h1>
        <SecondaryButton className={classes.testimonialsBtn} onClick={() => history.push(`${Consts.BASE_URL}/testimonials`)}>View Testimonials</SecondaryButton>
      </div>
      
      <div className={classes.testimonialsContainer}>
        <div className={classes.testimonialsCarousalWrapper}>
          <div className={classes.testimonialsCarousal}>
            {items.map((e, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    justifyContent: idx % 2 === 0 ? "flex-start" : "flex-end",
                  }}
                  className={classes.testimonialCardWrapper}
                >
                  {e?.text && (
                    <div className={classes.testimonialCard} style={{ transform: `rotate(${e.rotate})` }}>
                      <h2 className={classes.testimonialCardHeader}>
                        {e?.text}
                      </h2>
                      <div className={classes.testimonialCardContent}>
                        <div className={classes.testimonialCardAuthor}>
                          <img src={e.img} alt={e.name} />
                        </div>
                        <div className={classes.testimonialCardAuthorDetails}>
                          <h4>{e.name}</h4>
                          <hr />
                          <p>{e.book}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
