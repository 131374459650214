import React, { useEffect, useState } from "react";
import classes from "./TeamCard.module.css";
import Utils from "../../Utils";

function TeamCard({ teamPerson }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const tabs = Object.keys(teamPerson.details);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <article className={classes.card} onClick={handleClick}>
      <div
        className={Utils.getClasses(
          classes.cardInner,
          isFlipped ? classes.isFlipped : ""
        )}
      >
        <div className={classes.cardFront}>
          <div className={classes.cardContent}>
            <img
              src={teamPerson.image}
              alt={teamPerson.name}
              className={classes.userImage}
              loading="lazy"
            />
            <div className={classes.cardPersonDetails}>
              <h3>{teamPerson.name}</h3>
              <p>{teamPerson.designation}</p>
            </div>
          </div>
        </div>
        <div className={classes.cardBack}>
          <div className={classes.cardContent}>
            <div className={classes.profileCard}>
              <img src={teamPerson.image} alt={teamPerson.name} />
              <div className={classes.personDetails}>
                <div>
                  <h3>{teamPerson.name}</h3>
                  <p>{teamPerson.designation}</p>
                </div>
                <div className={classes.tabs}>
                  {tabs.map((tab) => (
                    <button
                      className={Utils.getClasses(
                        classes.tab,
                        activeTab === tab ? classes.active : ""
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveTab(tab);
                      }}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.tabContent}>
              <ol
              // className={isAnimating ? classes.animate : classes.tabContentList}
              >
                {teamPerson.details[activeTab].map((detail, index) => (
                  <li
                    key={index}
                    style={{
                      animationDelay: `${index * 0.2}s`,
                      transitionDelay: `${index * 0.25}s`,
                    }}
                  >
                    <span>{detail}</span>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default TeamCard;
