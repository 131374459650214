import { createSlice } from "@reduxjs/toolkit";

const testimonialsSlice = createSlice({
    name: "testimonials",
    initialState: {
        allTestimonials: [],
        testimonialsByType: [],
    },
    reducers: {
        // Fetch all testimonials
        fetchAllTestimonials: (state, action) => {
            state.allTestimonials = action.payload.testimonials;
        },

        // Fetch testimonials by type
        fetchTestimonialsByType: (state, action) => {
            state.testimonialsByType = action.payload.testimonials;
        },

        // Update a specific testimonial
        updateTestimonial: (state, action) => {
            const index = state.allTestimonials.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.allTestimonials.splice(index, 1, action.payload);
            }
        },

        // Add a newly created testimonial
        addCreatedTestimonial: (state, action) => {
            state.allTestimonials.push(action.payload.item);
        },

        // Remove a testimonial
        removeTestimonial: (state, action) => {
            const index = state.allTestimonials.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.allTestimonials.splice(index, 1);
            }
        },
    },
});

export const testimonialsActions = testimonialsSlice.actions;
export default testimonialsSlice.reducer;
