import React from 'react'
import classes from './SocialMediaShareButtons.module.css';
import facebookIcon from "../../assets/social/facebook.svg";
import youtubeIcon from "../../assets/social/youtube.svg";
import instaIcon from "../../assets/social/instagram.svg";
import whatsapp from "../../assets/social/whatsapp.svg";
import twitterIcon from "../../assets/social/twitter.svg";
import linkedInIcon from "../../assets/social/linkedin-round-svgrepo-com.svg";


const SocialMediaShareButtons = ({ blogUrlSlug, className, style }) => {
  const shareUrl = `${window.origin}/designer/share/blog/slug/${blogUrlSlug}`;
  const faceBookShare = `${shareUrl}?ogUrl=${encodeURIComponent('https://hi-in.facebook.com/')}`;
  return (
    <div className={className} style={style}>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${faceBookShare}`} className={classes.facebook} target='_blank' rel="noreferrer"><img className={classes.socialMediaIcon} src={facebookIcon} alt="facebook" /></a>
      <a href={`https://www.youtube.com/@Bookalooza?sub_confirmation=1`} className={classes.whatsapp} target='_blank' rel="noreferrer"><img className={classes.socialMediaIcon} src={youtubeIcon} alt="whatsapp" /></a>
      <a href={`https://api.whatsapp.com/send?text=${shareUrl}`} className={classes.whatsapp} target='_blank' rel="noreferrer"><img className={classes.socialMediaIcon} src={whatsapp} alt="whatsapp" /></a>
      <a href={`https://www.instagram.com/bookalooza`} className={classes.telegram} target='_blank' rel="noreferrer"><img className={classes.socialMediaIcon} src={instaIcon} alt="telegram" /></a>
      <a href={`https://twitter.com/bookalooza`} className={classes.telegram} target='_blank' rel="noreferrer"><img className={classes.socialMediaIcon} src={twitterIcon} alt="telegram" /></a>
      <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} className={classes.linkedin} target='_blank' rel="noreferrer"><img className={classes.socialMediaIcon} src={linkedInIcon} alt="linkedIn" /></a>

    </div>
  )
}
export default SocialMediaShareButtons