import React, { useState } from "react";
import classes from "../Testimonials.module.css";
import ReactPlayer from "react-player";
import StarRatings from "react-star-ratings";

function TestimonialsVideo({ testimonial }) {
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <div className={classes.video}>
      <ReactPlayer
        url={testimonial.videoUrl}
        fallback={<div>Loading...</div>}
        width="100%"
        height={245}
        light
        controls
        onPlay={() => {
          setIsPlaying(true);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
        playing={isPlaying}
      />
      {!isPlaying && (
        <>
          <div
            className={classes.videoImage}
            onClick={() => {
              setIsPlaying(true);
            }}
          >
            <img
              src={testimonial?.videoThumbUrl}
              alt={testimonial?.videoThumbUrl}
            />
          </div>
          <div
            className={classes.videoOverlay}
            onClick={() => {
              setIsPlaying(true);
            }}
          >
            <div className={classes.videoOverlayIcon}>
              <PlayIcon />
            </div>
            <div className={classes.videoOverlayContent}>
              <div style={{ display: "grid" }}>
                <span>{testimonial?.authorName}</span>
                <span className={classes.videoBookName}>
                  {testimonial?.bookName}
                </span>
              </div>
              <div className={classes.videoOverlayRating}>
                <StarRatings
                  rating={testimonial?.rating || 0}
                  starRatedColor={"var(--selection-dark)"}
                  numberOfStars={5}
                  starDimension="1.5rem"
                  starSpacing="0.25rem"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 75 75"
      version="1.2"
      width="72"
      className="opacity-90 hover:opacity-100 cursor-pointer hover:scale-105 transition"
    >
      <defs>
        <clipPath id="clip1">
          <path d="M 7.5 7.5 L 67.5 7.5 L 67.5 67.5 L 7.5 67.5 Z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip1)">
        <path
          d="M 67.5 37.5 C 67.5 20.933594 54.066406 7.5 37.5 7.5 C 20.933594 7.5 7.5 20.933594 7.5 37.5 C 7.5 54.066406 20.933594 67.5 37.5 67.5 C 54.066406 67.5 67.5 54.066406 67.5 37.5 Z"
          fill="var(--selection-dark)"
          fillOpacity="0.6"
        ></path>
      </g>
      <path
        d="M 52.335938 35.953125 L 31.1875 22.953125 C 30.609375 22.601562 29.847656 22.578125 29.253906 22.917969 C 28.648438 23.253906 28.273438 23.898438 28.273438 24.585938 L 28.273438 50.460938 C 28.273438 51.152344 28.648438 51.792969 29.242188 52.132812 C 29.53125 52.289062 29.847656 52.371094 30.171875 52.371094 C 30.523438 52.371094 30.863281 52.277344 31.164062 52.09375 L 52.3125 39.203125 C 52.882812 38.867188 53.230469 38.238281 53.230469 37.585938 C 53.253906 36.917969 52.90625 36.289062 52.335938 35.953125 Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
};

export default TestimonialsVideo;
