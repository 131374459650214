import axios from "axios";
import { testimonialsActions } from "./TestimonialsSlice";

export function fetchTestimonials(dispatch) {
    return axios({
        url: `/designer/testimonials`,  // API endpoint for fetching a single testimonial
        method: 'get',  // Use GET to fetch the data
        responseType: 'json',
    }).then((response) => {
        const { data } = response;
        if (data?.success) {
            dispatch(testimonialsActions.fetchAllTestimonials({ testimonials: data.data || []}));
            return response;
        }
        return response;
    }).catch((error) => {
        return error;
    });
}
