import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrimaryButton from "./components/Buttons/PrimaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Header from "./components/Header/Header";
import "./fabric.css";
import "./styles.css";
import "./resources/ws-all.css";
import SignUp from "./components/SignUp/SignUp";
import Loading from "../App/components/Loading/Loading";
import SignIn from "./components/SignIn/SignIn";
import Utils from "./Utils";
import MessageDialog from "./components/MessageDialog/MessageDialog";
import Dashboard from "./Dashbord";
import Consts from "./Consts";
import HomeScreen from "./modules/HomeScreen/HomeScreen";
import ForgotPassword from "./components/SignUp/ForgotPassword";
import BookStore from "./modules/Books/BookStore/BookStore";
import ProductScreen from "./modules/ProductScreen/ProductScreen";
import AboutUs from "./modules/AboutUs/AboutUs";
import ContactUs from "./modules/ContactUs/ContactUs";
import PricingAndRoyalties from "./modules/StaticScreens/PricingAndRoyalties";
import LicenseAgreement from "./modules/StaticScreens/LicenseAgreement";
import PrivacyPolicies from "./modules/StaticScreens/PrivacyPolicies";
import ShippingPolicy from "./modules/StaticScreens/ShippingPolicy";
import RefundAndCancellations from "./modules/StaticScreens/RefundAndCancellations";
import TermsAndCondition from "./modules/StaticScreens/TermsAndCondition";
import FrequentlyAskedQuestions from "./modules/StaticScreens/FrequentlyAskedQuestions";
import OrderResponse from "./components/PaymentForms/OrderResponse";
import Blogs from "./modules/Blogs/Blogs";
import StudentWorkShops from "./modules/WorkShops/StudentWorkShops";
import SchoolWorkShop from "./modules/WorkShops/SchoolWorkShop";
import NewBook from "./modules/Books/NewBook/NewBook";
import TagManager from "react-gtm-module";
import CannonicalTag from "./components/CannonicalTag";
import ConfirmUser from "./modules/ConfirmUser/ConfirmUser";
import { useSelector } from "react-redux";
import { PaymentContainer } from "./components/PaymentForms/Payment";
import Podcasts from "./modules/Podcasts/Podcasts";
import HelmetProvider from "./components/Helmet/Helmet";
import { Toaster } from "react-hot-toast";
import Testimonials from "./modules/Testimonials/Testimonials";

const tagManagerArgs = {
  gtmId: "GTM-KMLH74V8",
};
TagManager.initialize(tagManagerArgs);

const resourceLoader = {
  map: {},
  loadScript: function (src) {
    if (!this.map[src]) {
      this.map[src] = new Promise(function (resolve, reject) {
        var s = document.createElement("script");
        s.src = src;
        s.type = "text/javascript";
        s.onload = resolve;
        s.onerror = reject;
        s.defer = true;
        document.getElementsByTagName("head")[0].appendChild(s);
      });
    }
    return this.map[src];
  },
  loadCss: function (src) {
    if (!this.map[src]) {
      this.map[src] = new Promise(function (resolve, reject) {
        var s = document.createElement("link");
        s.rel = "stylesheet";
        s.type = "text/css";
        s.href = src;
        s.media = "all";
        s.onload = resolve;
        s.onerror = reject;
        document.getElementsByTagName("head")[0].appendChild(s);
      });
    }
    return this.map[src];
  },
  loadResources: async function () {
    let path = process.env.PUBLIC_URL;
    for (let i = 0, len = arguments.length; i < len; ++i) {
      let arg = arguments[i],
        method;
      if (!arg || !arg.src) continue;
      switch (arg.type) {
        case "css":
          method = "loadCss";
          break;
        default:
        case "js":
          method = "loadScript";
          break;
      }
      if (arg.src instanceof Array) {
        let arr = [];
        arg.src.forEach(function (e) {
          arr.push(resourceLoader[method](path + "/" + e));
        });
        await Promise.all(arr);
      } else if (typeof arg.src === "string") {
        await this[method](path + "/" + arg.src);
      }
    }
  },
};

function getBreakedText(text) {
  if (!text) return;
  let t = text.split("\n"),
    arr = [];
  arr.push(t[0]);
  for (let i = 1; i < t.length; ++i) {
    arr.push(<br />);
    arr.push(t[i]);
  }
  return arr;
}

let baseCannonicalUrls = [
  "bookalooza.com",
  "bookaloza.com",
  "wwww.bookalooza.com",
  "www.bookaloza.com",
];
baseCannonicalUrls = baseCannonicalUrls.reduce((prev, curr) => {
  prev.push(`https://${curr}`);
  prev.push(`http://${curr}`);
  return prev;
}, []);
baseCannonicalUrls.push(window.origin);

function RouterComponent({ Component, routeProps }) {
  const user = useSelector((state) => state.user.user || {});
  return Object.entries(user)?.length &&
    (!user?.email || !user?.phoneNumber) ? (
    <Redirect to={`${url}/update-user-information`} />
  ) : (
    <Component {...routeProps} />
  );
}

const url = Consts.BASE_URL;
export default function App() {
  const [initializing, setInitializing] = useState(true);
  const [alert, setAlert] = useState({ open: false, title: "Alert" });
  const [confirm, setConfirm] = useState({ open: false, title: "Confirm" });
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    window.error = setShowError;
    return () => delete window.error;
  }, []);

  const setupLozadObserver = useCallback(() => {
    let lozadObserver = window.global.lozad(".lozad", {
      load: function (el) {
        console.log("lozad loading el");
      },
    });
    window.global.lozadObserver = lozadObserver;
    lozadObserver.observe();
  }, []);

  const setupExtMsg = useCallback(() => {
    var Msg = window.global.Ext.Msg;
    Msg.alert = function (a, b) {
      setAlert({
        open: true,
        title: a,
        msg: b,
      });
    };
    Msg.confirm = function (title, msg, fn, scope) {
      setConfirm({
        open: true,
        title: title,
        msg: msg,
        callback: fn.bind(scope),
      });
    };
  }, []);

  useEffect(() => {
    let version = Consts.APP_VERSION;
    resourceLoader
      .loadResources(
        {
          src: ["js/ext.js?" + version, "js/fabricOverrides.js?" + version],
          type: "js",
        },
        { src: "js/ws.js?" + version, type: "js" }
      )
      .then(async function () {
        setInitializing(false);
        setupLozadObserver();
        setupExtMsg();
      })
      .catch((error) => {
        console.error("Error loading resources:", error);
        setInitializing(false);
      });
  }, [setupLozadObserver, setupExtMsg]);

  const dialogContent = {
    title: "Error",
    message: "Request failed, Please try again",
  };

  if (initializing) return <div>Loading</div>;

  return (
    <PaymentContainer>
      <CannonicalTag baseUrls={baseCannonicalUrls} />
      <HelmetProvider />
      <Header />
      <Loading />
      <Toaster />
      {showError && (
        <MessageDialog
          {...dialogContent}
          onCancel={() => setShowError(false)}
          isCancel
          closeText="Close"
          width={Consts.MESSAAGE_DIALOG_WIDTH}
          height={Consts.MESSAAGE_DIALOG_WIDTH}
        />
      )}
      <Switch>
        <Route
          exact
          path={url || "/"}
          render={(props) => (
            <RouterComponent Component={HomeScreen} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/update-user-information`}
          component={ConfirmUser}
        />
        <Route exact path={`${url}/sign-up`} component={SignUp} />
        <Route
          exact
          path={`${url}/sign-in`}
          render={(routeProps) =>
            Utils.isUserLoggedIn() ? (
              <Redirect to={url} />
            ) : (
              <SignIn {...routeProps} />
            )
          }
        />
        <Route
          exact
          path={`${url}/forgot-password`}
          render={(e) => <ForgotPassword />}
        />
        <Route
          exact
          path={`${url}/store`}
          render={(props) => (
            <RouterComponent Component={BookStore} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/details/:urlSlag`}
          render={(props) => (
            <RouterComponent Component={ProductScreen} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/about-us`}
          render={(props) => (
            <RouterComponent Component={AboutUs} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/contact-us`}
          render={(props) => (
            <RouterComponent Component={ContactUs} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/pricing-royalties`}
          render={(props) => (
            <RouterComponent
              Component={PricingAndRoyalties}
              routeProps={props}
            />
          )}
        />
        <Route
          exact
          path={`${url}/license-agreement`}
          render={(props) => (
            <RouterComponent Component={LicenseAgreement} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/privacy-policies`}
          render={(props) => (
            <RouterComponent Component={PrivacyPolicies} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/shipping-policy`}
          render={(props) => (
            <RouterComponent Component={ShippingPolicy} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/refund-cancellation`}
          render={(props) => (
            <RouterComponent
              Component={RefundAndCancellations}
              routeProps={props}
            />
          )}
        />
        <Route
          exact
          path={`${url}/terms-and-condition`}
          render={(props) => (
            <RouterComponent Component={TermsAndCondition} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/frequently-asked-questions`}
          render={(props) => (
            <RouterComponent
              Component={FrequentlyAskedQuestions}
              routeProps={props}
            />
          )}
        />
        {/* <Route
          path={`${url}/newsroom`}
          render={(props) => (
            <RouterComponent Component={NewsRoom} routeProps={props} />
          )}
        /> */}
        <Route
          path={`${url}/blogs`}
          render={(props) => (
            <RouterComponent Component={Blogs} routeProps={props} />
          )}
        />
        <Route
          path={`${url}/testimonials`}
          render={(props) => <RouterComponent Component={Testimonials} routeProps={props} /> }
        />
        <Route
          path={`${url}/podcasts`}
          render={(props) => (
            <RouterComponent Component={Podcasts} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/workshops/school`}
          render={(props) => (
            <RouterComponent Component={SchoolWorkShop} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/workshops/students`}
          render={(props) => (
            <RouterComponent Component={StudentWorkShops} routeProps={props} />
          )}
        />
        <Route
          exact
          path={`${url}/order-response`}
          render={(props) => (
            <RouterComponent Component={OrderResponse} routeProps={props} />
          )}
        />
        <Route
          path={`${url}/newbook`}
          render={(props) => (
            <RouterComponent Component={NewBook} routeProps={props} />
          )}
        />
        <Route
          path={url}
          render={(routeProps) =>
            Utils.isUserLoggedIn() ? (
              <RouterComponent Component={Dashboard} routeProps={routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: `${url}/sign-in`,
                  state: {
                    from: routeProps.location.pathname,
                  },
                }}
              />
            )
          }
        />
      </Switch>
      {alert.open && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <span>{getBreakedText(alert.title)}</span>
          </DialogTitle>
          <DialogContent>
            <span className="editor-text">{getBreakedText(alert.msg)}</span>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              onClick={() => {
                setAlert({ open: false, title: "Alert" });
              }}
              autoFocus
            >
              OK
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )}
      {confirm.open && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <span>{getBreakedText(confirm.title)}</span>
          </DialogTitle>
          <DialogContent>
            <span className="editor-text">{getBreakedText(confirm.msg)}</span>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              negative
              onClick={() => {
                var con = confirm;
                con.callback.call(con.scope, "cancel");
                setConfirm({ open: false });
              }}
            >
              Cancel
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                var con = confirm;
                con.callback.call(con.scope, "ok");
                setConfirm({ open: false });
              }}
              positive
            >
              OK
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )}
    </PaymentContainer>
  );
}
